<template>

<div class="search">

	<div class="search-inner">

		<com-inner>

			<div class="search-text" v-html="block.text" />

			<div class="search-box">

				<div class="search-box-prefix">{{ block.prefix }}</div>
				<div class="search-box-input">

					<input type="text" :placeholder="block.placeholder" v-model="text" />

					<div class="search-box-suggestions" v-if="suggestions.length">

						<router-link :to="$link(item.url, '', $route.meta.landing)" class="search-box-suggestions-item" v-for="(item, index) in suggestions" :key="index">
							{{ $constants.pageType[item.type] }} - {{ item.name }}
						</router-link>

					</div>

				</div>

			</div>

		</com-inner>

		<div class="search-avatar" />

	</div>

</div>

</template>

<script>

import mixBlock from './mixin.js'

export default {

	mixins: [mixBlock],

	data: function() {

		return {
			text: '',
			suggestions: []
		}

	},

	watch: {

		text: function(n) {

			if (n) { 
				
				this.search()

			} else {

				this.suggestions = []

			}

		}

	},

	methods: {

		search: function() {

			this.$api.cancel()

			this.$api.get('search', {
				text: this.text
			}).then(function(json) {

				this.suggestions = json.pages

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.search {
	background-color: #f5f5f5;
}

.search-inner {
	width: 100%;
	background-position: 50% 0px;
	background-repeat: no-repeat;
	background-size: auto 240px;
	padding: 40px 0px;
	height: 300px;
	z-index: 2;
}

.is-device .search-inner {
	padding: 40px 20px;
}

.is-mobile .search-inner {
	padding: 20px 10px;
	height: auto;
}

.is-aa .search-inner {
	background-image: url(~@/assets/search.aa.jpg);
}

.is-afa .search-inner {
	background-image: url(~@/assets/search.afa.jpg);
}

.is-stars .search-inner {
	background-image: url(~@/assets/search.stars.jpg);
}

.is-embed .search {
	margin-left: -20px;
	margin-top: -20px;
	margin-bottom: -20px;
	width: calc(100% + 40px);
}

.search-text {
	color: #fff;
	font-size: 30px;
	font-weight: lighter;
	text-align: center;
}

.is-device .search-text {
	font-size: 24px;
}

.is-mobile .search-text {
	font-size: 20px;
	line-height: 22px;
	text-align: left;
	width: calc(100% - 100px);
}

.search-text strong,
.search-text b {
	font-size: 32px;
	font-weight: bold;
}

.is-device .search-text strong,
.is-device .search-text b {
	font-size: 24px;
}

.is-mobile .search-text strong,
.is-mobile .search-text b {
	font-size: 20px;
}

.search-box {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 40px;
}

.is-device .search-box {
	flex-direction: column;
	margin-top: 20px;
}

.is-mobile .search-box {
	justify-content: flex-start;
	align-items: flex-start;
}

.search-box-prefix {
	flex-shrink: 0;
	width: 300px;
	color: #fff;
	font-size: 24px;
}

.is-device .search-box-prefix {
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
}

.is-mobile .search-box-prefix {
	font-size: 12px;
	text-align: left;
}

.search-box-input {
	width: 645px;
	height: 66px;
	background-color: rgba(52, 52, 52, 0.19);
	border-radius: 33px;
	padding: 10px;
}

.is-device .search-box-input {
	width: 100%;
}

.is-mobile .search-box-input {
	width: calc(100% - 80px);
	margin-left: -10px;
}

.search-box-input input {
	background-color: #fff;
	height: 46px;
	width: 100%;
	border-radius: 23px;
	padding: 0px 20px;
	font-size: 14px;
	color: #1a234c;
}

.search-box-input input::placeholder {
	text-transform: uppercase;
	color: #1a234c;
}

.search-box-suggestions {
	position: absolute;
	top: 57px;
	left: 10px;
	background-color: #fff;
	border-radius: 23px;
	width: 625px;
	padding: 5px 0px;
	max-height: 200px;
	overflow-y: auto;
	z-index: 3;
}

.is-mobile .search-box-suggestions {
	width: calc(100vw - 40px);
}

.search-box-suggestions-item {
	font-size: 14px;
	line-height: 18px;
	color: #1a234c;
	padding: 5px 20px;
	display: block;
}

.search-box-suggestions-item:hover {
	font-weight: bold;
}

.search-avatar {
	position: absolute;
	left: calc(50% + 460px);
	bottom: 0px;
	width: 280px;
	height: 530px;
	pointer-events: none;
	background-size: contain;
	background-position: 50% 100%;
	background-repeat: no-repeat;
	z-index: 2;
}

.is-mobile .search-avatar {
	width: 100px;
	height: 300px;
	left: auto;
	right: 0px;
	bottom: 0px;
}

.is-aa .search-avatar {
	background-image: url(~@/assets/avatar.aa.png);
}

.is-afa .search-avatar {
	background-image: url(~@/assets/avatar.afa.png);
}

.is-stars .search-avatar {
	background-image: url(~@/assets/avatar.stars.png);
}

</style>
